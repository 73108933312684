import React, { useState } from "react";
import { Seo } from "../../components/seo";
import Layout from "../../hoc/Layout/Layout";
import Booking from "../../components/Booking/Booking";
import Instructions from "../../components/Instructions/Instructions";
import Faq from "../../components/FAQ/FAQKontorsstadning";
import { SERVICE_TYPES } from "../../enums/serviceTypes";

// markup
const IndexPage = () => {
  const [bookingActive, setBookingActive] = useState(false);
  const [serviceChosen, setServiceChosen] = useState(false);
  const [ableToGoBack, setAbleToGoBack] = useState(false);
  const [finishBookingStep, setFinishBookingStep] = useState();

  return (
    <Layout
      bookingActive={bookingActive}
      serviceChosen={serviceChosen}
      ableToGoBack={ableToGoBack}
      finishBookingStep={finishBookingStep}
      setFinishBookingStep={setFinishBookingStep}
    >
      <Seo title="Kontorsstädning i Göteborg | Bilda Group" />
      <Booking
        bgType4
        setBookingActive={setBookingActive}
        choosenService={SERVICE_TYPES.Kontorsstädning}
        firstStep={2}
        setServiceChosen={setServiceChosen}
        setAbleToGoBack={setAbleToGoBack}
        finishBookingStep={finishBookingStep}
        setFinishBookingStep={setFinishBookingStep}
      />
      {!bookingActive && (
        <>
          <Instructions
            // bg
            text1="Ange företagets postnummer och hur stor yta som behöver städas."
            text2="Se pris och boka ett kostnadsfritt startmöte den tid som passar dig där vi går igenom städupplägg tillsammans."
            text3="Er städare kommer till er vid önskad tidpunkt och ni får njuta av ett rent och fräscht kontor"
            heading1="Berätta om ditt kontor"
            heading2="Välj en tid som passar dig"
            heading3="Njut av ett rent kontor"
          />
          {/* <GradeSection /> */}
          <Faq />
        </>
      )}
    </Layout>
  );
};

export default IndexPage;
